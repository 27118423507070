import React from 'react';
import LOGO from '../img/rh-new-logo.svg';
import PLACE_IMAGE from '../img/place-image.png';

export default function PurchaseComplete(props) {
    return (
        <div
            style={{
                padding: '1rem',
                marginBlock: '2rem',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            {/* Import Montserrat font */}
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap"
                rel="stylesheet"
            />

            <div style={{ maxWidth: '1200px', width: '100%' }}>
                <img src={LOGO} alt="logo" style={{ maxWidth: '20rem', marginBottom: '32px' }} />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        rowGap: '2rem'
                    }}
                >
                    <div style={{ maxWidth: '100%' }}>
                        <h1
                            style={{
                                fontWeight: 700,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '3.1875rem',
                                maxWidth: '460px',
                                letterSpacing: '-4%',
                                marginBottom: '24px'
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 'inherit',
                                    fontFamily: 'inherit',
                                    color: '#60a5da'
                                }}
                            >
                                Congratulations!
                            </span>{' '}
                            you successfully purchased a plan
                        </h1>

                        <p
                            style={{
                                fontWeight: 500,
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '1.25rem',
                                marginBottom: '24px'
                            }}
                        >
                            Click the button to assign the plan to beneficiaries
                        </p>

                        <button
                            style={{
                                backgroundColor: '#094063',
                                height: '54px',
                                width: '113px',
                                borderRadius: '40px',
                                color: 'white',
                                fontWeight: 600,
                                fontSize: '15px',
                                cursor: 'pointer'
                            }}
                            onClick={() => props.history.push('/dashboard/enrollees')}
                        >
                            Proceed
                        </button>
                    </div>

                    <div style={{ maxWidth: '1000%' }}>
                        <img
                            src={PLACE_IMAGE}
                            alt="purchase success"
                            style={{
                                width: '500px',
                                maxWidth: '100%'
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
