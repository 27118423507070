import React from 'react';

export const ItemContextSwitcher = ({ items, selectedItem, onItemSelected }) => {
    // check if url matches

    const isNew = window.location.pathname.includes('invoices/edit/new-invoice');
    // debugger;
    return (
        <div className="content-switcher plan_duration_btn_wrapper">
            {items.map((item, index) => (
                <button
                    key={`${item.id}_${index}`}
                    className={`
          dashboard-content-switcher__btn
          ${index === 0 ? 'content-switcher__btn_left-curve' : ''}
          ${index === items.length - 1 ? 'content-switcher__btn_right-curve' : ''}
          ${selectedItem && selectedItem.id === item.id ? 'content-switcher__btn_active' : ''}
        `}
                    onClick={() => onItemSelected(item)}
                >
                    {item.name}

                    {isNew && item.id === 3 && (
                        <>
                            {' - '}
                            <span style={{ color: '#27ae60', fontSize: '1.25rem' }}>5% off</span>
                        </>
                    )}
                </button>
            ))}
        </div>
    );
};
